<template>
  <div :id="type"
       style="height:500px;width:100%;">
  </div>
</template>
<script>

export default {
  props: {
    type: {
      default: 'p1'
    }
  },
  data () {
    return {
      nameList: ['政府车场', '商业车场'],
      dataDetailList: [{ name: '政府车场', value: 0 }, { name: '商业车场', value: 0 }]
    }
  },
  methods: {
    drawGraph () {
      document.querySelector("#" + this.type).style.width = window.innerWidth + 'px';
      let pieGraph = this.$echarts.init(document.getElementById(this.type));
      let option = {
        title: {
          text: '收入对比',
          x: '28%',
          y: '20%'
        },
        tooltip: {
          trigger: 'item',
          formatter: "类型 : {b} <br/>数值 : {c}"
        },
        legend: {
          orient: 'vertical',
          left: "10%",
          data: this.nameList
        },
        series: [
          {
            name: '支付数据',
            type: 'pie',
            radius: '55%',
            center: ['30%', '60%'],
            data: this.dataDetailList,
            itemStyle: {
              emphasis: {
                shadowBlur: 30,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      pieGraph.setOption(option, true);
    }
  },
  mounted () {
    this.drawGraph();
  },
  created () {
    this.$EventBus.$on('parkAmountByType', data => {
      this.dataDetailList = [];
      this.dataDetailList.push({ name: '政府车场', value: (data.govAmount / 100).toFixed(2) });
      this.dataDetailList.push({ name: '商业车场', value: (data.businessAmount / 100).toFixed(2) });
      this.drawGraph();
    })
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
#pieGraph
  height 400px
</style>
