<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <StatisticalSearch :searchType="searchType"></StatisticalSearch>
      <el-tabs type="card"
               v-model="activeName"
               @tab-click="handleTab">
        <el-tab-pane label="支付数据"
                     name="1">
          <div class="tableWrapper">
            <PieChart type="p1"
                      ref="pieChart1"></PieChart>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="停车数据" name="2">
        <div class="tableWrapper">
          <PieChart type ="P2" ref ="pieChart2"></PieChart>
        </div>
      </el-tab-pane> -->
        <!--<el-tab-pane label="PDA现金" name="5"></el-tab-pane>-->

      </el-tabs>

    </div>
  </div>
</template>
<script>
import StatisticalSearch from '../../../components/statisticalSearch/statisticalSearch';
import PieChart from '../../../components/pieChart/pieChart';
export default {
  name: 'govermentOrBusinessParkingContrast',
  data () {
    return {
      activeName: '1',
      searchType: 'contrast'
    }
  },
  methods: {
    handleTab (tab) {
      this.$refs.pieChart1.drawGraph()
    }
  },
  components: {
    StatisticalSearch,
    PieChart
  },
  beforeDestroy () {

  },
  created () {

  },
  computed: {},
  mounted () {

  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.tableWrapper
  width 100%
  height 500px
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  .searchWrapper
    overflow hidden
    background #EFF2F7
    padding 22px 22px 0
    margin-bottom 20px
</style>
