var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticStyle: { height: "500px", width: "100%" },
    attrs: { id: _vm.type },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }